import { SecurityContext } from '@lib';
import { navigate } from 'gatsby';
import * as React from 'react';
import { FC, useContext, useEffect } from 'react';

type NotificationsPageProps = {};

const NotificationsPage: FC<NotificationsPageProps> = () => {
  const { notificationsControl } = useContext(SecurityContext);

  useEffect(() => {
    if (notificationsControl) {
      navigate('/');
      notificationsControl.open();
    }
  }, [notificationsControl]);

  return null;
};

export default NotificationsPage;
